






import { Component, Vue, Provide } from 'vue-property-decorator';
import QuestionProvider from '@/providers/questions.provider';
import CourseList from '../../shared/components/CourseList.component.vue';
import { APPIDS, COURSE_QUESTIONS_STASTICS } from '../../enums';

@Component({
  components: { CourseList },
})
export default class ReportsCourseList extends Vue {
  @Provide() Provider = QuestionProvider;

  appId = APPIDS.COURSE_QUESTIONS_STASTICS;

  name = 'reportsCourseList';

  GoReport(courseData) {
    this.$router.push({
      name: COURSE_QUESTIONS_STASTICS.VIEW,
      params: {
        courseId: courseData.courseId.toString(),
      },
    });
  }
}
